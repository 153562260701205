
.title{
    font-size: 42px;
    color:var(--naranja);
}

.subtitle{
    font-size: px;
    color: var(--gris);
}

.content, .conditions{
    font-size: 12px;
    color: var(--gris);
    font-style: normal;
    font-weight: 400;
}
