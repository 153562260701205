.industry .title{
    color:var(--naranja);
    font-size: 42px;
}

.title-content h2{
    font-size: 20px;
    color: var(--azul);
}

@media (min-width:1366px){
    .title-content{
        height: 75px;
    }
    .industry-content{
        height: 155px;
    }
}

@media (min-width:1024px){
    .title-content{
        height: 85px;
    }
    .industry-content{
        height: 130px;
    }

    .title-content h2{
        font-size: 18px;
    }
}


@media (max-width: 800px){
    .industry .title-container{
        width: 100%;
    }
    .industry .title{
        font-size: 30px;
        text-align: center;
    }
    .title-content h2{font-size: 16px;}
}