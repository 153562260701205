.textContainer{
  margin-top: 80px;
}
.contentCarousel{
    display:flex;
    padding:0;
    position:absolute;
    top:0;
    width:100%;
  }
  
  .arrow{
    display:flex;
    justify-content:center;
    cursor: pointer;
  }
  
  .textCarousel h1{
    color: #EB6E16;
  }
  
  .textDescription{
    background: rgb(249 249 249 / 80%);
    border-radius: 16px;
  }
  
  .textDescription p{
    line-height: 21px;
    padding: 10px;
    color: #4D4D4D;
  }
  
  
  .featuresContent{
    padding-bottom: 8%;
  }
  
  .featuresContent img{
    width: 100%;
  }
  .featuresContent h2{
    font-size:20px;
    color:var(--azul-marino);
    line-height:30px;
    height:65px;
  }
  .reportsContent .line,
  .featuresContent .line{
    border:1px solid var(--gris);
    width:100%;
    margin-bottom:8px;
  }
  
  
  .evidenceContent h3{
    font-size: 20px;
    line-height: 30px;
    color: var(--naranja);
  }
  .evidenceContent h1{
    font-size: 42px;
    line-height: 50px;
    text-transform: uppercase;
    color: var(--azul-marino);
  }
  .evidenceContent img{
    width: 100%;
  }
  .evidenceContent .wave{
    position:absolute;
    bottom:5%;
    z-index:-1;
  }
  .evidenceContent .description{
    margin-top: 10%;
  }
  
  
  .reportsContent img{
    width: 100%;
  }
  .reportsContent h2{
    font-size:19px;
    color:var(--azul-marino);
    line-height:30px;
    padding-top: 8%;
    text-align: center;
  }
  .reportsContent button{
    margin-top: 10%;
    width: 100%;
  }
  .reportsContent p{
    text-align: center;
  }
  
  .technologyContent h2,
  .technologyContent span{
    font-size: 42px;
    line-height: 63px;
    color: var(--azul-marino);
  }
  .technologyContent span{
    color: var(--naranja);
  }
  .technologyContent h3{
    font-size: 20px;
    line-height: 30px;
    color: var(--azul);
  }
  .technologyContent .line{
    border: 1px solid var(--gris);
    width: 70%;
  }
  .technologyContent p{
    line-height: 21px;
  }
  .technologyContent button{
    margin-top: 20%;
    width: 100%;
  }
  
  .plans h2{
    font-size: 42px;
    line-height: 63px;
    color: var(--white);
    padding-top: 10%;
  }
  .plans h4{
    position: absolute;
    color: var(--white);
    font-size: 20px;
    top:15px;
  }
  .plans h3{
    font-size: 45px;
    line-height: 81px;
    color: var(--gris);
    text-align: center;
    padding-top: 4%;
  }
  .plans .span{
    text-align: center;
  }
  .plans .container{
    border:1px solid var(--gris);
    border-radius:25px;
    padding:5px;
  }
  .plans .features{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
  }
  .plans .features .header{
    display:flex;
    justify-content:center;
    align-items:center;	
      border-radius: 25px 25px 0px 0px;
  }
  
  .free{
    background: linear-gradient(112.62deg, #BDF7A3 -0.96%, #4FC3C6 41.14%, #009EE0 89.2%);
  }
  .basic{
    background: linear-gradient(101.82deg, #00EBFF -9.84%, #003DE6 103.38%);
  }
  .premium{
    background: linear-gradient(112.62deg, #FFE900 -0.96%, #FF13BD 89.2%);
  }
  
  @media (max-width:1366px) and (min-width:1025px){
    .evidenceContent .description{
      margin-top: 5%;
    }
  }
  
  @media (max-width:1024px){
    .evidenceContent .description{
      margin-top: 0%;
    }
  }
  
  @media (max-width:820px){
    .evidenceContent .wave{
      bottom:110px;
    }
  
    .reportsContent h2{
      padding-top: 1%;
    }
  
    .featuresContent h2{
      height:25px;
    }
  
    .technologyContent button{
      margin-top: 5%;
      margin-bottom: 5%;
    }
  }

  @media (max-width:600px){
    .textContainer{
      margin-top: 6px;
    }
    .textCarousel{
      text-align: center;
    }
  }
  
  @media (max-width:450px){
    .textContainer{
      margin-top: 40px;
    }
    .titleContainer{
      padding: 3%;
      background-color: red;
    }
    .titleContainer h1{
      font-size: 30px !important;
    }
  
  
    .evidenceContent .wave{
      bottom:140px;
    }
  
    .plans button{
      width: 100%;
    }
    .technologyContent h2,
    .technologyContent h3,
    .technologyContent p{
      text-align: center;
    }

    .technologyContent .line{
      width: 100%;
    }

    .btnTest{
      width: 100% !important;
    }
  }
  
  @media (max-width:350px){
    .textCarousel h1{
      font-size: 22px;
    }
    .titleContainer h1{
      font-size: 25px !important;
    }
    .featuresContent h2{
      height:50px;
    }

    .btn-test{
      width: 100%;
    }
  }