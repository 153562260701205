:root{
    --azul:#1D71B4;
    --azul-marino:#014980;
    --naranja:#EB6E16;
    --gris:#4D4D4D;
    --white:#FFFFFF;
}
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-display: swap;

}
body{
    font-family:'Poppins';
    font-weight: 400;
}

.home.menu-container{
    position:absolute;
    width:100%;
    z-index: 2;
}

.flex{
    display: flex;
}

.menu{
    margin-top: 2%;
}

.brand{
    display: flex;
    justify-content: center;
}
.brand img{
    width: 100%;
}

.menu{
    height:95px;
}
.menu-logo{
    position:relative;
    display:inline-block;
    width:20%;
    padding:7px;
    height:100%;
}
.menu-logo img{
    width: 85%;
}
.menu .menu-content{
    position:relative;
    display:inline-block;
    width:80%;
    height:100%;
}
.menu .menu-content .items{
    width:70%;
    display:inline-block;
    height:100%;
}
.menu .menu-content .items .content{
    height: 100%;
}
.menu .menu-content .buttons{
    display:inline-block;
    width:30%;
}
.menu .menu-content .buttons .content{
    width: 100%;
}
.menu .menu-content .buttons .content .button{
    margin-right:5px;
    display:inline-block;
}
.menu .menu-content .buttons .content .button.register,
.menu .menu-content .buttons .content .button.login{
    width: 40%;
}
.menu .menu-content .buttons .content .button.flag{
    width: 15%;
    text-align: center;
}
.menu .menu-content .buttons .content .button.flag img{
    width: 50%;
}


.menu-item{
    cursor: pointer;
    text-align: center;    
    border-radius: 6px;
    background: var(--azul);
    position:relative;
    display:inline-block;
    padding:3px;
    padding-left:20px;
    padding-right:20px;
    margin-right:5px;
}
.menu-item span{
    font-size: 13px;
    font-weight: bold;
    color: var(--white);
}

.menu-item-line{
    background-color: var(--naranja);
    height: 5px;
    border-radius: 5px;
    position: relative;
    top: -2px;
    margin:3px;
}

.btn-demo,
.btn-register,
.btn-test,
.btn-login{
    border-radius: 27px;
    font-weight: 600;
    font-size: 13px;
}
.btn-test,
.btn-login{    
    background: linear-gradient(94.6deg, #c97133 7.17%, #EB6E16 134.17%);
    color:#FFFFFF;
}
.btn-test:hover,
.btn-login:hover{
    background: linear-gradient(94.38deg, #EB6E16 7.2%, #2574D0 109.67%);;
}

.btn-demo{
    color: var(--white);
    background-color: var(--azul);
    border: 2px solid var(--white);
}
.btn-demo:hover{
    border:2px solid var(--naranja);
    background-color: var(--naranja) !important;
    color: var(--white);
    background-color: transparent;
}

.btn-register{
    border: 2px solid #142C69;
    /*border-image-source: linear-gradient(94.6deg, #2B78BA 7.17%, #142C69 134.17%);*/
    color:var(--naranja);    
}
.btn-register:hover{
    border: 2px solid var(--naranja);
    background: linear-gradient(94.6deg, #2B78BA 7.17%, #142C69 134.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

h1, h2{
    font-style: normal;
    font-weight: 700;
}
h3, h4, p{
    font-style: normal;
    font-weight: 600;
}
span, p{
    font-size: 14px;
    color: var(--gris);
}
span{
    font-style: normal;
    font-weight: 600;
}
p{
    font-weight: 400;
}

.titleContainer{
    padding: 8%;
}
.titleContainer h1{
    color:var(--azul-marino); 
    font-size:42px;
    font-family: 'Poppins';
    line-height:63px;
}
.titleContainer .titleLine{
    width:100%;
    border:3px solid var(--naranja);
}

.slick-prev:before, .slick-next:before,
.slick-prev, .slick-next{
    color:var(--gris) !important;
}
.footer{
    background-color: var(--azul);
    color: var(--white);
}

.footer span,
.footer a{
    color: var(--white);
}

.schedule{
    width: 20%;
    position: fixed;
    bottom: 7px;
    right: 75px;
    z-index: 3;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}
.schedule .images {
    padding: 2px;
}
.schedule .images img{    
    background-color: var(--white);
}


.menu-movile{
    background: rgb(255, 255, 255);
    position: absolute;
    right: 0;
    top: 43px;
    border-radius: 5px;
    z-index: 2;
    visibility: hidden;
}

@media (min-width:1025px){
    .btn-register,
    .btn-test,
    .btn-login{
        width: 100%;
    }
}

@media (min-width:1024px) and (max-width:1310px){
    .menu-logo{
       width: 15%;
       display: flex;
       align-items: center;
    }
    .menu .menu-content{
       width: 85%;
    }
    .menu-content .items .menu-item{
       padding-left: 10px;
       padding-right: 10px;
    }
    .menu .menu-content .buttons .content .button.register,
    .menu .menu-content .buttons .content .button.login{
        width: 39%;
    }
}
@media (max-width:1023px){
    .menu{
        display: none;
    }
}

/*SOBREESCRIBE*/
@media (min-width:992px){
    .rs-col-md-offset-6 {
        margin-left: 0% !important;
    }
}
@media (max-width:800px){
    .schedule{
        width: 25%;
    }

}
@media (max-width:800px){
    .schedule{
        width: 55%;
    }
}

@media (max-width:1024px){
    .menu-movile{
        top:58px;
        justify-content: center;
        padding: 5px;
    }
    .menu-item{
        width: 100%;
        margin-top:3px
    }

    .buttons .content .button{
        margin-top: 3px;        
    }
    .buttons .content .button button{
        width: 100%;
    }

    .button.flag{
        width: 15%;
        float: right;
    }
}

@media (max-width:600px){
    .menu-movile{
        top:45px;
    }
}
@media (max-width:450px){
    .titleContainer h1{
      font-size: 30px !important;
    }
}
@media (max-width:350px){
    .titleContainer h1{
      font-size: 25px !important;
    }
}


.iti.iti--allow-dropdown{
    width: 100%;
}